// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';
import { createContext, useReducer, useRef } from 'react';

import { MyToastsContainer } from '../component/Notification';

const NotificationContext = createContext();

const initialState = {
  toasts: [],
};

const NotificationProvider = (props) => {
  const notifications = useRef([]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const add = (message) => {
    const id = Math.floor(Math.random() * 10000000);
    dispatch({ type: 'ADD_TOAST', payload: { id, message } });
  };
  const remove = (id) => {
    dispatch({ type: 'DELETE_TOAST', payload: id });
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        add,
        remove,
      }}
    >
      <MyToastsContainer toasts={state.toasts} />
      {props.children}
    </NotificationContext.Provider>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case 'DELETE_TOAST': {
      const updatedToasts = state.toasts.filter((toast) => toast.id !== action.payload);
      return {
        ...state,
        toasts: updatedToasts,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { NotificationContext, NotificationProvider };
