// © Copyright IBM Corp. 2022, 2024

import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import ClientApp from './clientapp/App';
import config from './config';
import reportWebVitals from './reportWebVitals';

import './App.scss';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>{window.location.hostname === config.myDomain ? <App /> : <ClientApp />}</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
