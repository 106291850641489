// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Link } from 'react-router-dom';

import { Card, CardContent, CardHeader } from '@mui/material';

export const Dashboard = () => (
  <Card>
    <CardHeader title="Raptor Administration Portal" />
    <CardContent>
      <h2>Welcome to Raptor&apos;s MVP Release!</h2>

      <p>
        An MVP, or <i>Minimally Viable Product</i>, is a version of a product with just enough features to be usable by early customers who can then provide
        feedback for future product development. What you see here is very raw and is evolving.
      </p>
      <p>This release is focused around a few core concepts and features:</p>

      <h3>Zero Trust DNS capabilities</h3>
      <ul>
        <li>
          Support for DNS-Over-HTTPS (DoH) and DNS-Over-TLS (DoT) protocols. These are called <b>Services</b>.
        </li>
        <li>
          Attach custom Certificate Authorities to Services. This allows you to use mTLS for DoT and have precise control over who uses your service(s). (N.B.
          mTLS with DoH can work, but is not supported by browsers.)
        </li>
        <li>
          Rich policy expression using the OPA framework with the Rego language. This is currently exposed in its raw form. It is unlikely we will expose
          OPA/Rego directly to customers in future releases.
        </li>
        <li>
          Large scale matching via <b>Domain Lists</b>. Here you can specify large lists of domain names and refer to them in policies.
        </li>
      </ul>

      <h3>Identity</h3>
      <ul>
        <li>
          Our data model is centered on <b>Organizations</b>. These are top-level objects that contain all the configuration data; e.g. Services, Policies, etc.
        </li>
        <li>
          Organizations can have multiple Identity Providers. Currently these are sources of users and related attributes that can be matched against in
          Policies.
        </li>
      </ul>

      <p>
        See the <Link to="/help">Help Page</Link> for more information.
      </p>
    </CardContent>
  </Card>
);
