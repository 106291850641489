// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { useGetRecordRepresentation, useRecordContext } from 'react-admin';

import { Link } from '@carbon/react';

import { Breadcrumbs, Typography } from '@mui/material';

export const MyBreadcrumbs = ({ path }) => {
  const crumb = path.split('/').slice(1, 2);
  const currentUrl = window.location.href;
  const rec = useRecordContext();
  const getRecordRepresentation = useGetRecordRepresentation(crumb);

  var p = '#';
  return (
    <div role="presentation" style={{ marginBottom: '5px' }}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: '5px' }}>
        <Link underline="hover" color="inherit" href="/#/">
          Home
        </Link>

        {path
          ?.split('/')
          .slice(1)
          .map((i, k, a) => {
            p = p + '/' + i;
            return rec || k < a.length - 1 ? (
              <Link underline="hover" color="inherit" href={p} key={k}>
                {i}
              </Link>
            ) : currentUrl.endsWith('create') ? (
              <Link underline="hover" color="inherit" href={p} key={k}>
                {i}
              </Link>
            ) : (
              <Typography color="text.primary" key={k}>
                {i}
              </Typography>
            );
          })}

        {rec ? (
          <Typography color="text.primary">{getRecordRepresentation(rec).substring(1)}</Typography>
        ) : (
          currentUrl.endsWith('create') && <Typography color="text.primary">create</Typography>
        )}
      </Breadcrumbs>
    </div>
  );
};
