// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { useDeleteMany, useRefresh } from 'react-admin';

import { NotificationContext } from '../context/notificationContext';
import { useHandleError } from '../hooks/useHandleError';

export const useBatchDeleteAction = () => {
  const [deleteMany] = useDeleteMany();
  const refresh = useRefresh();
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const batchDeleteAction = async ({ resource, rowsToDelete, setRowsToDelete, setConfirmationOpen, msg }) => {
    deleteMany(
      resource,
      { ids: rowsToDelete },
      {
        onSuccess: () => {
          refresh();
          // pluralize string if more than one item is deleted
          if (rowsToDelete.length > 1) {
            msg = msg.slice(-1) === 'y' ? msg.replace(/.$/, 'ies') : msg + 's';
          }
          notificationCtx.add({
            msg: `${msg.replace(/[^-\s]+/g, (s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())} deleted!`,
            status: 'warning',
          });
        },
        onError: (err) => {
          handleError({ err: err?.body?.details || {}, resource: resource });
        },
      },
    );
    setRowsToDelete();
    setConfirmationOpen(false);
  };

  return { batchDeleteAction };
};
