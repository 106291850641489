// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import {
  CreateBase,
  EditBase,
  List,
  regex,
  required,
  SimpleForm,
  useCreate,
  useEditContext,
  useListContext,
  usePermissions,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';

import { Typography } from '@mui/material';

import './styles.css';

import { v4 as uuidv4 } from 'uuid';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar, HazPerms } from '../component/customComponents';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextInputField } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { RulesCard } from '../component/rules';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const headers = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'updatedAt',
    header: 'Last Updated',
  },
  {
    key: 'createdAt',
    header: 'Created At',
  },
];

export const Table = () => {
  const { permissions, isLoading } = usePermissions();
  const redirect = useRedirect();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data || isLoading) return null;

  const clone = structuredClone(data);

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  clone?.forEach((result, idx) => {
    // conditionally add edit link
    if (HazPerms(permissions, 'rules', 'edit')) {
      result.edit = <MyEdit url={`/rules/${result.id}`} key={`edit-${idx}`} />;
      if (!headers.some((e) => e.key === 'edit')) headers.push({ key: 'edit', header: 'Edit' });
    }
    result.list_count = result.list?.length || 0;
    result.updatedAt = <Typography variant="body2"> {new Date(result.updated_at).toLocaleString()}</Typography>;
    result.createdAt = <Typography variant="body2"> {new Date(result.created_at).toLocaleString()}</Typography>;
  });
  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title="Are you sure you want to delete these Domain Lists?"
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'rules',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Rule',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        resource="rules"
        data={clone}
        headers={headers}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', 'rules')}
        bulkAction={confirmBulkDelete}
      />
    </>
  );
};

export const RulesForm = ({ props, isEdit = false }) => {
  const [create] = useCreate();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const { handleError } = useHandleError();
  const notificationCtx = React.useContext(NotificationContext);
  const [data, setData] = React.useState({ operator: 'and', not: false, conditions: [] });
  const { isLoading, isFetching, record } = useEditContext();

  React.useEffect(() => {
    if (record) setData({ ...record, conditions: record.conditions.reduce((a, v) => ({ ...a, [uuidv4()]: v }), {}) });
  }, [record]);

  if (isLoading || isFetching) {
    return (
      <div className="container">
        <div className="loading">
          <div className="loading__letter">L</div>
          <div className="loading__letter">o</div>
          <div className="loading__letter">a</div>
          <div className="loading__letter">d</div>
          <div className="loading__letter">i</div>
          <div className="loading__letter">n</div>
          <div className="loading__letter">g</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
          <div className="loading__letter">.</div>
        </div>
      </div>
    );
  }

  const save = async (d) => {
    let { conditions: cond, ...rest } = data;
    if (cond instanceof Object) {
      cond = Object.values(cond);
    }
    const payload = { ...d, type: 'rule', ...rest, conditions: cond };

    if (isEdit) {
      update(
        'rules',
        { id: payload.id, data: payload },
        {
          onSuccess: () => {
            redirect('/rules');
            notificationCtx.add({ msg: 'Rule updated!', details: '', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'rules' });
          },
        },
      );
    } else {
      create(
        'rules',
        { data: payload },
        {
          onSuccess: () => {
            redirect('/rules');
            notificationCtx.add({ msg: 'Rule created!', details: '', status: 'success' });
          },
          onError: (err) => {
            handleError({ err: err?.body?.details || {}, resource: 'rules', create: true });
          },
        },
      );
    }
  };

  return (
    <SimpleForm
      disableInvalidFormNotification
      {...props}
      toolbar={<CustomToolbar redirectPath={'/rules'} resource="rules" showDelete={isEdit ? true : false} />}
      onSubmit={save}
    >
      <CarbonTextInputField
        source="name"
        label="Name"
        validate={[required('Name is required'), regex(/^[a-zA-Z0-9_-]+$/, 'Enter a valid name consisting of letters, numbers, underscores or hyphens.')]}
        {...props}
      />
      <input type="hidden" label="Description" id="description" />

      <RulesCard {...props} data={data} setData={setData} />
    </SimpleForm>
  );
};

export const RulesCreate = ({ props }) => {
  const notificationCtx = React.useContext(NotificationContext);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/rules');
    refresh();
    notificationCtx.add({ msg: 'Rule created.', status: 'success' });
  };
  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'rules', create: true });
  };

  return (
    <CreateBase {...props} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={'/rules'} />
      <RulesForm
        defaultValues={{
          name: '',
          description: '',
          list: [],
        }}
      />
    </CreateBase>
  );
};

export const RulesEdit = ({ props }) => {
  const notificationCtx = React.useContext(NotificationContext);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { handleError } = useHandleError();

  const onSuccess = () => {
    redirect('/rules');
    refresh();
    notificationCtx.add({ msg: 'Rule updated.', status: 'success' });
  };
  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'rules' });
  };

  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={'/rules'} />
      <RulesForm {...props} isEdit={true} />
    </EditBase>
  );
};

export const RulesList = () => {
  return (
    <>
      <MyBreadcrumbs path={'/rules'} />
      <List perPage={10} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false}>
        <Table />
      </List>
    </>
  );
};
