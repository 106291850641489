// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { NotificationContext } from '../context/notificationContext';

export const useHandleError = () => {
  const notificationCtx = React.useContext(NotificationContext);

  const genNotification = (m, d) => {
    return notificationCtx.add({
      msg: m,
      details: d,
      status: 'error',
    });
  };

  const handleError = ({ err, resource, create, msg = '' }) => {
    if (typeof err === 'string') {
      return genNotification(`Failed to ${create ? 'create' : 'update'} ${resource}.`, `${msg} ${err}`);
    } else {
      for (const [key, value] of Object.entries(err)) {
        if (typeof value === 'string') {
          return genNotification(`Failed to ${create ? 'create' : 'update'} ${resource}.`, `${msg} ${key}: ${value}`);
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            return handleError({ err: val, resource: resource, create: create, msg: `${msg} ${key}:` });
          });
        } else {
          for (const [k, v] of Object.entries(value)) {
            if (typeof v === 'object') {
              return handleError({ err: v, resource: resource, create: create, msg: `${msg} ${key}:` });
            } else if (Array.isArray(v)) {
              v.forEach((val) => {
                return handleError({ err: val, resource: resource, create: create });
              });
            }
            genNotification(`Failed to ${create ? 'create' : 'update'} ${resource}.`, `${msg} ${key}: ${Array.isArray(value) ? '' : k + ':'} ${v}`);
          }
        }
      }
    }
  };

  return { handleError };
};
