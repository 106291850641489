// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { List, useListContext } from 'react-admin';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { MyTable } from '../component/MyTable';

const headers = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'provider_id',
    header: 'Provider',
  },
  {
    key: 'endpoint',
    header: 'Endpoint',
  },
  {
    key: 'config',
    header: 'Additional Config',
  },
  {
    key: 'extra',
    header: 'Request Token Extra Arguments',
  },
];

const Table = () => {
  const { data } = useListContext();

  data?.forEach((result) => {
    result.config = JSON.stringify(result.additional_config);
    result.extra = JSON.stringify(result.request_token_extra_arguments);
  });

  return <MyTable data={data} headers={headers} showActions={false} />;
};
export const IDPList = () => (
  <>
    <MyBreadcrumbs path={'/idp'} />
    <List perPage={10} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false}>
      <Table />
    </List>
  </>
);
