// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { List, useListContext } from 'react-admin';

import { Checkmark, MisuseOutline } from '@carbon/icons-react';

import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { MyTable } from '../component/MyTable';

import './styles.css';

const headers = [
  {
    key: 'id',
    header: 'Feed Id',
  },
  {
    key: 'isActive',
    header: 'Is Active',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'description',
    header: 'Description',
  },
  {
    key: 'tags',
    header: 'Tags',
  },
  {
    key: 'updatedAt',
    header: 'Last Updated',
  },
];

export const Table = () => {
  const { data } = useListContext();

  data?.forEach((r, i) => {
    r.isActive = r.is_active ? <Checkmark /> : <MisuseOutline />;
    r.updatedAt = <Typography variant="body2"> {new Date(r.updated_at).toLocaleString()}</Typography>;
    r.tags = (
      <Stack spacing={{ xs: 1, sm: 1 }} direction="row" useFlexGap sx={{ flexWrap: 'wrap', marginTop: '5px', marginBottom: '5px' }}>
        {r.metadata.map((t) => (
          <Chip label={t} key={`${t}-${i}`} />
        ))}
      </Stack>
    );
  });
  return (
    <>
      <MyTable data={data} headers={headers} showActions={false} zebra={false} title="Global Domain Lists" />
    </>
  );
};

export const GlobalDomainListList = () => {
  return (
    <>
      <MyBreadcrumbs path={'/globaldomainlist'} />
      <List perPage={10} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false}>
        <Table />
      </List>
    </>
  );
};
