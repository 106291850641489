// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Modal } from '@carbon/react';

const BulkActionModal = ({ confirmationOpen, title, body, batchActionClick, setConfirmationOpen, setRowsToDelete }) => {
  return (
    <Modal
      open={confirmationOpen}
      onRequestClose={() => {
        setConfirmationOpen(false);
        setRowsToDelete();
      }}
      danger
      modalHeading={title}
      modalLabel=""
      primaryButtonText="Delete"
      secondaryButtonText="Cancel"
      onRequestSubmit={batchActionClick}
    >
      {body}
    </Modal>
  );
};

export { BulkActionModal };
