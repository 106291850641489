// © Copyright IBM Corp. 2022, 2024

// this isn't private don't put private things here
const config = {
  apiUrl: process.env.REACT_APP_RAPTOR_API_URL.replace(/\/$/, ''),
  myDomain: (process.env.REACT_APP_MY_DOMAIN || 'my.ns1routing.net').replace(/\/$/, ''),
  clientBasePath: (process.env.REACT_APP_RAPTOR_CLIENT_BASE_PATH || '/v1/client').replace(/\/$/, ''),
  csrfCookieName: (process.env.REACT_APP_RAPTOR_CSRF_TOKEN_NAME || 'csrftoken').replace(/\/$/, ''),
};

export default config;
