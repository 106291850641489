// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';
import { useState } from 'react';

import { CreateBase, Form, List, PasswordInput, required, SimpleForm, TextInput, useCreate, useListContext, useRedirect, useUnique } from 'react-admin';

import { Modal } from '@carbon/react';

import { Typography } from '@mui/material';
import * as chrono from 'chrono-node';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar } from '../component/customComponents';
import { CarbonTextInputField } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const headers = [
  { key: 'id', header: 'ID'  },
  { key: 'name', header: 'Name', isSortable: true, sortKey: 'name' },
  { key: 'createdAt', header: 'Date Created', isSortable: true, sortKey: 'date_created'  },
  { key: 'expiresAt', header: 'Expires At', isSortable: true, sortKey: 'expires_at'  },
];

const Table = () => {
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState([]);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const redirect = useRedirect();
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data) return null;

  data?.forEach((result) => {
    result.createdAt = <Typography variant="body2"> {new Date(result.date_created).toLocaleString()}</Typography>;
    result.expiresAt = <Typography variant="body2"> {result.expires_at ? new Date(result.expires_at).toLocaleString() : 'Never'}</Typography>;
  });

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title={'Are you sure you want to delete these Access Tokens?'}
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() => {
          batchDeleteAction({
            resource: 'accesstoken',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Access Token',
          });
        }}
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        resource="accesstoken"
        data={data}
        headers={headers}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', 'accesstoken')}
        bulkAction={confirmBulkDelete}
      />
    </>
  );
};

export const AccessTokenList = () => {
  return (
    <>
      <MyBreadcrumbs path={'/accesstoken'} />
      <List actions={false} perPage={5} pagination={false} sort={{ field: 'name', order: 'ASC' }}>
        <Table />
      </List>
    </>
  );
};

export const AccessTokenCreate = () => {
  const unique = useUnique();
  const redirect = useRedirect();
  const [token, setToken] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();
  const [create] = useCreate();

  const handleCloseClick = () => {
    redirect('/accesstoken');
  };

  const onCreateSuccess = (data) => {
    setToken(data['token']);
    setShowDialog(true);
    notificationCtx.add({ msg: 'token created', status: 'success' });
    redirect(false);
  };

  const onCreateError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'accesstoken' });
  };

  const handleCopy = () => {
    document.getElementById('htoken').select();
    document.execCommand('copy');
    notificationCtx.add({ msg: 'Token copied to clipboard', status: 'success' });
  };

  const parseExpiresIn = (e) => {
    if (!e) return 0;

    const now = Math.floor(new Date().getTime() / 1000);
    const expires = chrono.parseDate(e, new Date(), { forwardDate: true });
    const endTime = Math.floor(expires.getTime() / 1000);
    return endTime - now;
  };

  const validateExpiry = (e) => {
    if (!e) {
      return undefined;
    }

    let errorMsg = { message: '', args: { source: 'expires_sec', value: e, field: 'expires_sec' } };
    try {
      if (parseExpiresIn(e) < 1) {
        return { ...errorMsg, message: 'Token set to expire in past.' };
      }
    } catch (err) {
      return { ...errorMsg, message: 'invalid input, please see examples' };
    }
    return undefined;
  };

  const save = async (d) => {
    // validate will catch invalid expiry input, so we can just call the parse function without catching it here
    const payload = { ...d, expires_at: chrono.parseDate(d.expires_at, new Date(), { forwardDate: true }) };

    create(
      'accesstoken',
      { data: payload },
      {
        onError: onCreateError,
        onSuccess: onCreateSuccess,
      },
    );
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={'/accesstoken'} />
      <>
        <SimpleForm
          reValidateMode="onBlur"
          onSubmit={save}
          disableInvalidFormNotification
          toolbar={<CustomToolbar redirectPath={'/accesstoken'} resource="accesstoken" showDelete={false} />}
        >
          <CarbonTextInputField
            label="Token Name"
            source="name"
            validate={[required('This field is required'), unique({ message: 'Token name is not unique' })]}
          />
          <CarbonTextInputField
            label="Expires in"
            source="expires_at"
            validate={[validateExpiry]}
          />
          <Typography style={{ whiteSpace: 'pre-line' }} component={'span'}>
            Input is natural language.{'\n'}
            <strong>Examples:</strong>
            <ul style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              <li>1 month</li>
              <li>in 30 mins</li>
              <li>next Friday</li>
              <li>07/31/2028</li>
            </ul>
          </Typography>
        </SimpleForm>
        <Modal
          open={showDialog}
          modalHeading={'One Time Token Dialog'}
          secondaryButtonText="Close"
          primaryButtonText="Copy"
          onRequestClose={handleCloseClick}
          onRequestSubmit={handleCopy}
          preventCloseOnClickOutside
        >
          <Typography variant="h8">Please copy your token. After closing this dialog it will not be presented again.</Typography>
          <Form record={{ token: token }} toolbar={false}>
            <PasswordInput
              style={{ marginTop: '10px' }}
              source="token"
              onClick={(event) => event.target.type === 'text' && event.target.select()}
              sx={{ width: '100%' }}
            ></PasswordInput>

            <TextInput source="token" sx={{ position: 'absolute', left: '-1000px', top: '-1000px' }} id="htoken" />
          </Form>
        </Modal>
      </>
    </CreateBase>
  );
};
