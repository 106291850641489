// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@carbon/react';

import EditIcon from '@mui/icons-material/Edit';

class MyEdit extends React.Component {
  render() {
    return (
      <Link to={this.props.url} reloadDocument>
        <Button kind="ghost" as="div" style={{ width: '70px', paddingLeft: '0px' }} renderIcon={EditIcon}>
          Edit
        </Button>
      </Link>
    );
  }
}

export { MyEdit };
