// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { useListContext, usePermissions } from 'react-admin';

import {
  Button,
  DataTable,
  DataTableSkeleton,
  Pagination,
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
} from '@carbon/react';
import { Add, TrashCan } from '@carbon/react/icons';

import { HazPerms } from './customComponents';

const MyTable = ({
  data,
  headers,
  addRow,
  bulkAction,
  zebra = false,
  showActions = true,
  title = '',
  description = '',
  showPagination = true,
  resource = '',
}) => {
  const { isPending, page, perPage, total, setPage, setPerPage, setSort, sort } = useListContext();
  const { permissions, isLoading } = usePermissions();

  if (isPending || !data || isLoading) return <DataTableSkeleton aria-label="loading table" headers={headers} compact zebra />;

  const handleChange = (event) => {
    setPerPage(event.pageSize);
    setPage(event.page);
  };

  const handleChangeSort = (event) => {
    const { currentTarget } = event;
    const { columnId } = currentTarget.dataset;
    setSort({ field: columnId });
  };

  return (
    <>
      <DataTable isSortable rows={data} headers={headers} useZebraStyles={zebra}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getToolbarProps,
          getBatchActionProps,
          selectedRows,
          getTableProps,
          getTableContainerProps,
          selectRow,
        }) => {
          const batchActionProps = {
            ...getBatchActionProps({
              onSelectAll: () => {
                rows.map((row) => {
                  if (!row.isSelected) {
                    selectRow(row.id);
                  }
                });
              },
            }),
          };
          return (
            <TableContainer {...getTableContainerProps()} title={title} description={description}>
              {showActions && (
                <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                  {HazPerms(permissions, resource, 'delete') && (
                    <TableBatchActions {...batchActionProps}>
                      <TableBatchAction
                        tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                        renderIcon={TrashCan}
                        onClick={() => bulkAction(selectedRows)}
                      >
                        Delete
                      </TableBatchAction>
                    </TableBatchActions>
                  )}
                  {HazPerms(permissions, resource, 'create') && (
                    <TableToolbarContent>
                      <Button kind="ghost" renderIcon={Add} onClick={addRow} style={{ marginRight: '3px' }}>
                        Create
                      </Button>
                    </TableToolbarContent>
                  )}
                </TableToolbar>
              )}

              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {showActions && HazPerms(permissions, resource, 'delete') && <TableSelectAll {...getSelectionProps()} />}
                    {headers.map((header) => {
                      if (!HazPerms(permissions, resource, 'edit') && header.header.toLowerCase() === 'edit') return null;
                      let { key: _, ...props } = { ...getHeaderProps({ header, isSortable: !!header.isSortable }) };
                      return (
                        <TableHeader
                          key={`header-${header.key}`}
                          {...props}
                          onClick={handleChangeSort}
                          data-column-id={header.sortKey}
                          sortDirection={sort.order}
                          isSortHeader={sort.field === header.sortKey}
                        >
                          {header.header}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={`table-row-${i}`} props={{ ...getRowProps({ row }) }}>
                      {showActions && HazPerms(permissions, resource, 'delete') && (
                        <TableSelectRow
                          {...getSelectionProps({
                            row,
                          })}
                        />
                      )}
                      {row.cells.map((cell) => {
                        if (!HazPerms(permissions, resource, 'edit') && cell.info.header.toLowerCase() === 'edit') return null;
                        return <TableCell key={`cell-${cell.id}`}>{cell.value}</TableCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      {showPagination && (
        <Pagination
          backwardText="Previous page"
          forwardText="Next page"
          itemsPerPageText="Items per page:"
          onChange={handleChange}
          page={page || 1}
          pageSize={perPage || 5}
          pageSizes={[2, 5, 10, 20, 30, 40, 50]}
          size="md"
          totalItems={total}
        />
      )}
    </>
  );
};

export { MyTable };
