// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { useGetList } from 'react-admin';

import { AlluvialChart, DonutChart, LineChart } from '@carbon/charts-react';
import { Column, FlexGrid, Heading, Row, Section } from '@carbon/react';

import DnsIcon from '@mui/icons-material/Dns';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import PeopleIcon from '@mui/icons-material/People';
import TrafficIcon from '@mui/icons-material/Traffic';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Card } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { CardWithLink } from '../component/customComponents';

export const Metrics = () => {
  const { total: policies } = useGetList('policy');
  const { total: policy_chains } = useGetList('policy_chain');
  const { total: domains } = useGetList('domainlist');
  const { total: ca } = useGetList('ca');
  const { total: environment } = useGetList('environment');
  const { total: entities } = useGetList('entity');
  const { total: idp } = useGetList('idp');
  const { total: accesstokens } = useGetList('accesstoken');

  const generateDates = () => {
    const dates = [];
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    while (thirtyDaysAgo <= today) {
      dates.push(new Date(thirtyDaysAgo));
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() + 1);
    }

    return dates;
  };

  const genData = (dataLabel) => {
    return generateDates().map((d) => ({
      group: dataLabel,
      date: d,
      value: Math.floor(Math.random() * (Math.random() * (100000 - 1) + 1 - 0) + 0),
    }));
  };

  return (
    <>
      <MyBreadcrumbs path="/metrics" />
      <Card sx={{ paddingLeft: '5px', backgroundColor: 'unset', paddingBottom: '5px', boxShadow: 'unset', marginBottom: '15px' }}>
        <FlexGrid fullWidth>
          <Row>
            <Section level={5}>
              <Heading>ZNS</Heading>
            </Section>
          </Row>
          <Row narrow>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/ca" icon={VerifiedIcon} title={'Total CAs'} subtitle={ca} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/policy" icon={TrafficIcon} title={'Total Policies'} subtitle={policies} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/policy_chain" icon={TrafficIcon} title={'Total Policy Chains'} subtitle={policy_chains} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/environment" icon={MultipleStopIcon} title={'Total Environments'} subtitle={environment} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/domainlist" icon={DnsIcon} title={'Total Domains'} subtitle={domains} />
            </Column>
          </Row>

          <Row>
            <Section level={5}>
              <Heading>Identities</Heading>
            </Section>
          </Row>
          <Row narrow>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/entity" icon={PeopleIcon} title={'Total Entities'} subtitle={entities} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/idp" icon={FolderSharedIcon} title={'Total Identity Providers'} subtitle={idp} />
            </Column>
          </Row>

          <Row>
            <Section level={5}>
              <Heading>Other</Heading>
            </Section>
          </Row>
          <Row narrow>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/accesstoken" icon={PeopleIcon} title={'Total Access Tokens'} subtitle={accesstokens} />
            </Column>
          </Row>
          <hr />
          <Row>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400, maxWidth: 400 }}>
                <LineChart
                  data={genData('QPS')}
                  options={{
                    title: 'QPS',
                    color: {
                      scale: {
                        QPS: 'blue',
                      },
                    },
                    axes: {
                      bottom: {
                        scaleType: 'time',
                        mapsTo: 'date',
                        ticks: {
                          rotation: 'always',
                        },
                      },
                      left: {
                        mapsTo: 'value',
                      },
                    },
                    legend: {
                      clickable: false,
                    },
                  }}
                ></LineChart>
              </Card>
            </Column>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400, maxWidth: 400 }}>
                <LineChart
                  data={genData('Count by day')}
                  options={{
                    title: 'Domains Blocked',
                    color: {
                      scale: {
                        'Count by day': 'orange',
                      },
                    },
                    axes: {
                      bottom: {
                        scaleType: 'time',
                        mapsTo: 'date',
                        ticks: {
                          rotation: 'always',
                        },
                      },
                      left: {
                        mapsTo: 'value',
                      },
                    },
                    legend: {
                      clickable: false,
                    },
                    toolbar: {
                      enabled: false,
                    },
                  }}
                ></LineChart>
              </Card>
            </Column>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400, maxWidth: 400 }}>
                <LineChart
                  data={genData('Redirects')}
                  options={{
                    title: 'Domains Intercepted',
                    color: {
                      scale: {
                        Redirects: 'green',
                      },
                    },
                    axes: {
                      bottom: {
                        scaleType: 'time',
                        mapsTo: 'date',
                        ticks: {
                          rotation: 'always',
                        },
                        highlights: {
                          highlightStartMapsTo: 'startHighlight',
                          highlightEndMapsTo: 'endHighlight',
                          labelMapsTo: 'label',
                          data: [
                            {
                              startHighlight: new Date().setDate(new Date().getDate() - 20),
                              label: 'Custom formatter',
                              endHighlight: new Date().setDate(new Date().getDate() - 15),
                            },
                            {
                              startHighlight: new Date().setDate(new Date().getDate() - 10),
                              label: 'Custom formatter',
                              endHighlight: new Date().setDate(new Date().getDate() - 3),
                            },
                          ],
                        },
                      },
                      left: {
                        mapsTo: 'value',
                      },
                    },
                    legend: {
                      clickable: false,
                    },
                  }}
                ></LineChart>
              </Card>
            </Column>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 600 }}>
                <AlluvialChart
                  data={[
                    {
                      source: 'User5',
                      target: 'Blocked',
                      value: 5,
                    },
                    {
                      source: 'User5',
                      target: 'Private NS',
                      value: 4,
                    },
                    {
                      source: 'User5',
                      target: 'Static Record',
                      value: 3,
                    },
                    {
                      source: 'User5',
                      target: 'Allowed',
                      value: 4,
                    },
                    {
                      source: 'User5',
                      target: 'Intercepted',
                      value: 8,
                    },
                    {
                      source: 'User1',
                      target: 'Blocked',
                      value: 6,
                    },
                    {
                      source: 'User1',
                      target: 'Private NS',
                      value: 15,
                    },
                    {
                      source: 'User1',
                      target: 'Static Record',
                      value: 2,
                    },
                    {
                      source: 'User1',
                      target: 'Allowed',
                      value: 10,
                    },
                    {
                      source: 'User1',
                      target: 'Intercepted',
                      value: 13,
                    },
                    {
                      source: 'User2',
                      target: 'Blocked',
                      value: 2,
                    },
                    {
                      source: 'User2',
                      target: 'Private NS',
                      value: 15,
                    },
                    {
                      source: 'User2',
                      target: 'Static Record',
                      value: 1,
                    },
                    {
                      source: 'User2',
                      target: 'Allowed',
                      value: 6,
                    },
                    {
                      source: 'User2',
                      target: 'Intercepted',
                      value: 15,
                    },
                    {
                      source: 'User3',
                      target: 'Private NS',
                      value: 14,
                    },
                    {
                      source: 'User3',
                      target: 'Static Record',
                      value: 3,
                    },
                    {
                      source: 'User3',
                      target: 'Allowed',
                      value: 3,
                    },
                    {
                      source: 'User4',
                      target: 'Blocked',
                      value: 4,
                    },
                    {
                      source: 'User4',
                      target: 'Private NS',
                      value: 8,
                    },
                    {
                      source: 'User4',
                      target: 'Intercepted',
                      value: 13,
                    },
                  ]}
                  options={{
                    title: "User's results",
                    alluvial: {
                      nodes: [
                        {
                          name: 'User5',
                          category: 'Pattern',
                        },
                        {
                          name: 'User1',
                          category: 'Pattern',
                        },
                        {
                          name: 'User2',
                          category: 'Pattern',
                        },
                        {
                          name: 'User4',
                          category: 'Pattern',
                        },
                        {
                          name: 'User3',
                          category: 'Pattern',
                        },
                        {
                          name: 'Blocked',
                          category: 'Group',
                        },
                        {
                          name: 'Private NS',
                          category: 'Group',
                        },
                        {
                          name: 'Static Record',
                          category: 'Group',
                        },
                        {
                          name: 'Allowed',
                          category: 'Group',
                        },
                        {
                          name: 'Intercepted',
                          category: 'Group',
                        },
                      ],
                    },
                    height: '600px',
                  }}
                ></AlluvialChart>
              </Card>
            </Column>
            <Column>
              <DonutChart
                data={[
                  {
                    group: 'Allowed',
                    value: 20000,
                  },
                  {
                    group: 'Blocked',
                    value: 65000,
                  },
                  {
                    group: 'Intercepted',
                    value: 75000,
                  },
                  {
                    group: 'NS1 Answer',
                    value: 1200,
                  },
                  {
                    group: 'Forward',
                    value: 10000,
                  },
                  {
                    group: 'Private Authority',
                    value: 25000,
                  },
                  {
                    group: 'Static Answer',
                    value: 15030,
                  },
                ]}
                options={{
                  title: 'Action Types',
                  resizable: true,
                  donut: {
                    center: {
                      label: 'Count of Action Types',
                    },
                  },
                  height: '400px',
                }}
              ></DonutChart>
            </Column>
          </Row>
        </FlexGrid>
      </Card>
    </>
  );
};
