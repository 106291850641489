// © Copyright IBM Corp. 2022, 2024

import React from 'react';

import { JsonInput } from 'react-admin-json-view';

const JsonEditor = (props) => {
  const { source } = props;

  return (
    <>
      <JsonInput
        source={source}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: false,
          enableClipboard: true,
          displayDataTypes: false,
        }}
      />
    </>
  );
};

export { JsonEditor };
