// © Copyright IBM Corp. 2022, 2024

import React, { useState } from 'react';

import { useDataProvider, useGetList, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Add, TrashCan } from '@carbon/icons-react';
import { Button, Stack } from '@carbon/react';

import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';

import { CarbonTextInputField, MyMultiSelect, MySelect, MyToggle } from '../component/MyInputs';

const ArrayComponent = (props) => {
  const [arrayValues, setArrayValues] = useState(props.value || []);

  const handleInputChange = (index, newValue) => {
    const updatedArray = [...arrayValues];
    updatedArray[index] = newValue;
    setArrayValues(updatedArray.filter((f) => f !== ''));
    props.handleOnChange(updatedArray);
  };

  const handleAddElement = (e) => {
    e.preventDefault();
    setArrayValues([...arrayValues, '']);
  };

  const handleRemoveElement = (index, e) => {
    e.preventDefault();
    const updatedArray = [...arrayValues];
    updatedArray.splice(index, 1);
    setArrayValues(updatedArray);
    props.handleOnChange(updatedArray);
  };

  return (
    <div>
      <Button
        onClick={handleAddElement}
        kind="primary"
        size="sm"
        style={{ marginBottom: '10px' }}
        hasIconOnly
        renderIcon={Add}
        tooltipPosition="right"
        iconDescription="Add new item"
      />
      {arrayValues.map((element, index) => (
        <div key={index} style={{ display: 'flex' }}>
          <div>
            <CarbonTextInputField style={{ width: '100%' }} label="" type="text" value={element} onChange={(e) => handleInputChange(index, e.target.value)} />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              onClick={(e) => handleRemoveElement(index, e)}
              kind="secondary"
              size="sm"
              renderIcon={TrashCan}
              hasIconOnly
              tooltipPosition="left"
              iconDescription="Remove item"
              className="cds--btn--danger"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const MetadataComponent = (props) => {
  const [values, setValues] = useState(props.value || []);

  const handleInputChange = (index, newValue, key) => {
    const updatedArray = [...values];
    updatedArray[index] = { ...updatedArray[index], [key]: newValue };
    setValues(updatedArray.filter((f) => f !== ''));
    props.handleOnChange(updatedArray);
  };

  const handleAddElement = (e) => {
    e.preventDefault();
    setValues([...values, { key: '', operator: '==', value: '' }]);
  };

  const handleRemoveElement = (index, e) => {
    e.preventDefault();
    const updatedArray = [...values];
    updatedArray.splice(index, 1);
    setValues(updatedArray);
    props.handleOnChange(updatedArray);
  };

  return (
    <div>
      <div>
        <Typography sx={{ fontSize: '0.75em', paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>Metadata</Typography>
        <Button
          onClick={handleAddElement}
          kind="primary"
          size="sm"
          style={{ marginBottom: '10px' }}
          hasIconOnly
          renderIcon={Add}
          tooltipPosition="right"
          iconDescription="Add new item"
        />
      </div>
      {values.map((element, index) => (
        <div key={index} style={{ display: 'flex' }}>
          <div style={{ marginRight: '15px' }}>
            <CarbonTextInputField
              style={{ width: '100%' }}
              label="key"
              type="text"
              value={element.key}
              onChange={(e) => handleInputChange(index, e.target.value, 'key')}
            />
          </div>
          <div style={{ marginRight: '15px' }}>
            <MySelect label="operator" value={element.operator} choices={myOperators} onChange={(e) => handleInputChange(index, e.target.value, 'operator')} />
          </div>
          <div style={{ marginRight: '15px' }}>
            <CarbonTextInputField
              style={{ width: '100%' }}
              label="value"
              type="text"
              value={element.value}
              onChange={(e) => handleInputChange(index, e.target.value, 'value')}
            />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              onClick={(e) => handleRemoveElement(index, e)}
              kind="secondary"
              size="sm"
              renderIcon={TrashCan}
              hasIconOnly
              tooltipPosition="left"
              iconDescription="Remove item"
              className="cds--btn--danger"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const myOperators = [
  { name: '<', id: '<' },
  { name: '<=', id: '<=' },
  { name: '==', id: '==' },
  { name: '>=', id: '>=' },
  { name: '>', id: '>' },
  { name: 'in', id: 'in' },
];

const ConditionHeader = ({ title }) => {
  return (
    <>
      <Typography variant="h6" component="div" sx={{ color: 'text.secondary', paddingRight: '15px' }}>
        Condition Type:
      </Typography>
      <Typography component="div" variant="h6">
        {' '}
        {title}
      </Typography>
    </>
  );
};

const ConditionCard = ({ title, children, id, type, state, setState, removeSelf, onChange, hideNot = false }) => {
  const handleToggleChange = () => {
    let t = { ...state, not: !state.not };
    setState(t);
    onChange(id, t);
  };

  const remove = () => {
    removeSelf(id, type);
  };

  return (
    <Card style={{ width: '100%', backgroundColor: 'beige', overflow: 'visible' }} key={id}>
      <CardContent>
        <Stack orientation="vertical" gap={4}>
          <div style={{ display: 'flex' }}>
            <ConditionHeader title={title} />
            <div style={{ marginLeft: 'auto' }}>
              <Button
                size="sm"
                hasIconOnly
                renderIcon={TrashCan}
                className="cds--btn--danger"
                kind="primary"
                iconDescription="Remove"
                tooltipPosition="left"
                onClick={remove}
              />
            </div>
          </div>
          {!hideNot && (
            <div style={{ display: 'flex' }}>
              <Typography gutterBottom sx={{ paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>
                Not
              </Typography>
              <MyToggle defaultValue={state.not} labelText="" labelA="On" labelB="Off" onChange={handleToggleChange} />
            </div>
          )}

          {children}
        </Stack>
      </CardContent>
    </Card>
  );
};

// commented out until this is implemented in the API
// const NetworkCondition = (props) => {
//   const { onChange, id, value } = props;
//   const [state, setState] = useState(value);

//   const handleChange = (d, key) => {
//     let t = { ...state, [key]: d };
//     setState(t);
//     onChange(id, t);
//   };

//   return (
//     <ConditionCard title={'Network'} type="network" state={state} setState={setState} {...props}>
//       <div>
//         <Typography sx={{ fontSize: '0.75em', paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>Geo Locations</Typography>
//         <ArrayComponent {...props} handleOnChange={(d) => handleChange(d, 'geo_locations')} value={state.geo_locations} />
//       </div>

//       <div>
//         <Typography sx={{ fontSize: '0.75em', paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>Client Subnets</Typography>
//         <ArrayComponent {...props} handleOnChange={(d) => handleChange(d, 'client_subnets')} value={state.client_subnets} />
//       </div>
//     </ConditionCard>
//   );
// };

const RuleRefCondition = (props) => {
  const { onChange, id, value } = props;
  const [state, setState] = useState(value);
  const record = useRecordContext();

  const { data, isLoading } = useGetList('rules', { pagination: { page: 1, perPage: 100 }, sort: { field: 'name', order: 'ASC' } });

  if (isLoading) return null;

  const handleChange = (d, key) => {
    let t = { ...state, [key]: d };
    setState(t);
    onChange(id, t);
  };

  return (
    <ConditionCard title={'Rule Reference'} type="rule_ref" state={state} setState={setState} {...props} hideNot>
      <div style={{ marginRight: '15px' }}>
        <MySelect
          showEmpty
          label=""
          value={state.rule_id}
          choices={data?.map((r) => ({ id: r.id, name: r.name })).filter((f) => f.id !== record?.id)}
          onChange={(e) => (e.target.value === '---' ? null : handleChange(e.target.value, 'rule_id'))}
        />
      </div>
    </ConditionCard>
  );
};

const ClientCondition = (props) => {
  const { onChange, id, value } = props;

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(value);
  const [envClients, setEnvClients] = React.useState({});

  React.useEffect(() => {
    const getEnvClients = async (envID) => {
      const list = await dataProvider.getList(`environment/${envID}/client`, {
        filter: {},
        sort: { field: 'id', order: 'DESC' },
        pagination: { page: 1, perPage: 1000 },
      });

      list?.data?.map((r) => {
        setEnvClients((old) => ({ ...old, [r.id]: r.name }));
      });
    };

    const getEnvs = async () => {
      const list = await dataProvider.getList('environment', {
        filter: {},
        sort: { field: 'id', order: 'DESC' },
        pagination: { page: 1, perPage: 1000 },
      });
      await Promise.all(list?.data?.map((envID) => getEnvClients(envID.id)));
      setLoading(false);
    };

    getEnvs();
  }, []);

  if (loading) return null;

  const handleChange = (d, key) => {
    let t = { ...state, [key]: d };
    setState(t);
    onChange(id, t);
  };

  return (
    <ConditionCard title={'Client'} type="client" state={state} setState={setState} {...props}>
      <div>
        <MyMultiSelect
          title="Environment Client List"
          className="domainListSelect"
          choices={Object.keys(envClients).map((k) => ({ id: k, text: envClients[k] }))}
          onChange={(d) => handleChange(d, 'ids')}
          selected={state.ids || []}
        />
      </div>
      <div>
        <MetadataComponent handleOnChange={(d) => handleChange(d, 'metadata')} value={state.metadata} />
      </div>
    </ConditionCard>
  );
};

const DomainCondition = (props) => {
  const { onChange, id, value } = props;

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(value);
  const [domainListChoices, setDomainListChoices] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    const getDomainList = async () => {
      if (loading) {
        const list = await dataProvider.getList('domainlist', {
          filter: {},
          sort: { field: 'id', order: 'DESC' },
          pagination: { page: 1, perPage: 1000 },
        });
        setDomainListChoices(list?.data?.map((r) => ({ id: r.id, text: r.name })));

        const cat = await dataProvider.getList('globaldomainlist/categories', {
          filter: {},
          sort: { field: 'id', order: 'DESC' },
          pagination: { page: 1, perPage: 1000 },
        });

        setCategories(cat?.data?.map((r) => ({ id: r.id, text: r.name })));

        setLoading(false);
      }
    };

    getDomainList();
  }, []);

  if (loading) return null;

  const handleChange = (d, key) => {
    let t = { ...state, [key]: d };
    setState(t);
    onChange(id, t);
  };

  const handleToggleChange = () => {
    let t = { ...state, match_subdomains: !state.match_subdomains };
    setState(t);
    onChange(id, t);
  };

  return (
    <ConditionCard title={'Domain List'} type="domain" state={state} setState={setState} {...props}>
      <div style={{ display: 'flex' }}>
        <Typography gutterBottom sx={{ paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>
          Match Subdomains
        </Typography>
        <MyToggle source="match_subdomains" defaultValue={state.match_subdomains} labelText="" labelA="On" labelB="Off" onChange={handleToggleChange} />
      </div>
      <div>
        <MyMultiSelect
          title="Domain List"
          className="domainListSelect"
          choices={domainListChoices}
          onChange={(d) => handleChange(d, 'domain_lists')}
          selected={state.domain_lists || []}
        />
      </div>

      <div>
        <MyMultiSelect
          title="Global Domain List Categories"
          className="categories"
          choices={categories}
          onChange={(d) => handleChange(d, 'categories')}
          selected={state.categories || []}
        />
      </div>

      <div>
        <Typography sx={{ fontSize: '0.75em', paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>Domains</Typography>
        <ArrayComponent {...props} handleOnChange={(d) => handleChange(d, 'domains')} value={state.domains} />
      </div>
      {/* disable the private_zones until implemented in the API */}
      {/* <div>
        <Typography sx={{ fontSize: '0.75em', paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>Private Zones</Typography>
        <ArrayComponent {...props} handleOnChange={(d) => handleChange(d, 'private_zones')} value={state.private_zones} />
      </div> */}
    </ConditionCard>
  );
};

const MatchAllCondition = (props) => {
  const { value } = props;

  const [state, setState] = useState(value);

  return <ConditionCard title={'Match All'} type="match_all" state={state} setState={setState} {...props} />;
};

const RulesCard = ({ data, setData }) => {
  const { setValue: markDirty, getValues } = useFormContext();

  const hasMatchAll = () => {
    return !!Object.values(data.conditions).filter((f) => f.type === 'match_all').length;
  };

  const loadCondition = (key, item) => {
    const uuid = key || uuidv4();
    switch (item.type) {
      case 'match_all':
        return <MatchAllCondition key={uuid} removeSelf={removeItem} onChange={handleDataChange} id={uuid} value={item} />;
      case 'domain':
        return <DomainCondition key={uuid} removeSelf={removeItem} onChange={handleDataChange} id={uuid} value={item} />;
      case 'client':
        return <ClientCondition key={uuid} removeSelf={removeItem} onChange={handleDataChange} id={uuid} value={item} />;
      // case 'network':
      // return <NetworkCondition key={uuid} removeSelf={removeItem} onChange={handleDataChange} id={uuid} value={item} />;
      case 'rule_ref':
        return <RuleRefCondition key={uuid} removeSelf={removeItem} onChange={handleDataChange} id={uuid} value={item} />;
      default:
      // console.log(`unsupported condition type: ${item}`);
      // do nothing
    }
  };

  const addItem = (item) => {
    const uuid = uuidv4();
    // mark form dirty so the save button is enabled
    const currentValues = getValues();
    markDirty('description', currentValues.description + '', { shouldDirty: true });
    switch (item.type) {
      case 'match_all':
        setData((oldData) => ({
          ...oldData,
          conditions: {
            ...oldData.conditions,
            [uuid]: { type: 'match_all', not: false },
          },
        }));
        break;
      case 'domain':
        setData((oldData) => ({
          ...oldData,
          conditions: {
            ...oldData.conditions,
            [uuid]: { type: 'domain', not: false, domains: [], private_zones: [], domain_lists: [], match_subdomains: false },
          },
        }));
        break;
      case 'client':
        setData((oldData) => ({
          ...oldData,
          conditions: {
            ...oldData.conditions,
            [uuid]: { type: 'client', not: false, id: [] },
          },
        }));
        break;
      case 'network':
        // do nothing since this is not implemented in the API yet
        // setData((oldData) => ({
        //   ...oldData,
        //   conditions: {
        //     ...oldData.conditions,
        //     [uuid]: { type: 'network', not: false, client_subnets: [], geo_locations: [] },
        //   },
        // }));
        break;
      case 'rule_ref':
        setData((oldData) => ({
          ...oldData,
          conditions: {
            ...oldData.conditions,
            [uuid]: { type: 'rule_ref', not: false },
          },
        }));
        break;
      default:
      // do nothing
    }
  };

  // removes the component and related data by uuid reference
  const removeItem = (id) => {
    // mark form dirty so the save button is enabled
    const currentValues = getValues();
    markDirty('description', currentValues.description + '', { shouldDirty: true });
    setData((old) => {
      const newObject = { ...old };
      delete newObject.conditions[id];
      return newObject;
    });
  };

  const handleDataChange = (idx, value) => {
    // mark form dirty so the save button is enabled
    const currentValues = getValues();
    markDirty('description', currentValues.description + '', { shouldDirty: true });
    setData((oldData) => ({
      ...oldData,
      conditions: {
        ...oldData.conditions,
        [idx]: value,
      },
    }));
  };

  return (
    <Card sx={{ marginBottom: '15px', overflow: 'visible' }}>
      <CardContent>
        <Typography component="div" variant="h5">
          Rule
        </Typography>
        <Stack orientation="vertical" gap={4}>
          <Stack orientation="horizontal" gap={4}>
            <Button onClick={() => addItem({ type: 'client' })}>+ Client</Button>
            <Button onClick={() => addItem({ type: 'domain' })}>+ Domain</Button>
            {/* disable network until implemented */}
            {/* <Button onClick={() => addItem({ type: 'network' })}>+ Network</Button> */}
            <Button onClick={() => addItem({ type: 'match_all' })} disabled={hasMatchAll()}>
              + Match All
            </Button>
            <Button onClick={() => addItem({ type: 'rule_ref' })}>+ Rule Reference</Button>
          </Stack>
          <MySelect
            source="operator"
            label="Operator"
            choices={[
              { id: 'and', name: 'and' },
              { id: 'or', name: 'or' },
            ]}
            onChange={(e) => setData({ ...data, operator: e.target.value })}
          />
          <div style={{ display: 'flex' }}>
            <Typography gutterBottom sx={{ paddingTop: '5px', color: 'text.secondary', paddingRight: '15px' }}>
              Not
            </Typography>
            <MyToggle
              source="not"
              labelText=""
              labelA="On"
              labelB="Off"
              onChange={() => {
                setData((oldData) => ({ ...oldData, not: !oldData.not }));
              }}
            />
          </div>
          {Object.keys(data.conditions).map((c) => loadCondition(c, data.conditions[c]))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export { RuleRefCondition, RulesCard };
