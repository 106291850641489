// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { ProgressBar, ToastNotification } from '@carbon/react';

import { NotificationContext } from '../context/notificationContext';

const MyNotification = ({ status, msg, details = '', handleClose }) => {
  const timeout = 7000;
  const [progress, setProgress] = useState(timeout);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((currentProgress) => {
        return currentProgress - 50;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <ToastNotification
      aria-label="closes notification"
      kind={status}
      onClose={function noRefCheck() {
        handleClose();
      }}
      onCloseButtonClick={function noRefCheck() {
        handleClose();
      }}
      role="status"
      statusIconDescription="notification"
      timeout={timeout}
      title={msg}
      style={{ float: 'right', width: 'auto', marginLeft: 'auto', opacity: '0.95', paddingBottom: '15px', position: 'relative' }}
    >
      {details}
      <ProgressBar className={`progress-${status}`} value={progress} max={timeout} status={'active'} label="" type="default" size="small" hideLabel />
    </ToastNotification>
  );
};

const MyToastsContainer = ({ toasts }) => {
  const notificationCtx = useContext(NotificationContext);

  const handleDismiss = (id) => {
    notificationCtx.remove(id);
  };

  return (
    <div className="toasts-container">
      {toasts?.map((toast) => (
        <MyNotification key={toast.id} handleClose={() => handleDismiss(toast.id)} {...toast.message} />
      ))}
    </div>
  );
};

export { MyNotification, MyToastsContainer };
