// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';

export const HelpPage = () => (
  <>
    <MyBreadcrumbs path={'/help'} />
    <Card>
      <CardHeader title="Help" />
      <CardContent>
        <h2>Policies</h2>

        <h3>What is in the Session Context?</h3>
        <p>
          The following are available for use in policies. They are part of the input document, so refer to each using the <code>input.</code> prefix.
        </p>
        <ul>
          <li>
            <code>authn_context.service</code>: (string) ID of the Service that received the query
          </li>
          <li>
            <code>authn_context.cert_fingerprint</code>: (string) for mTLS cases, the fingerprint of the CA that matched
          </li>
          <li>
            <code>authn_context.source_proto</code>: (string) query protocol: doh, dot, dns
          </li>
          <li>
            <code>request_context.domain</code>: (string) name being queried for (unqualified; without trailing &apos;.&apos;)
          </li>
          <li>
            <code>request_context.qtype</code>: (string) type being queried for (A, AAAA, etc.)
          </li>
          <li>
            <code>request_context.source_ip</code>: (string) query source IP address
          </li>
          <li>
            <code>request_context.source_port</code>: (string) query source port
          </li>
          <li>
            <code>request_context.ecs_ip</code>: (string) source IP address from EDNS client subnet (if present)
          </li>
          <li>
            <code>request_context.best_ip</code>: (string) the &quot;best&quot; IP address to use (of source_ip, or ecs_ip). This is always the ECS address, if
            present.
          </li>
          <li>
            <code>request_context.ecs_is_best</code>: (boolean) true if the best_ip is the ecs_ip
          </li>
          <li>
            <code>request_context.metadata</code>: (dictionary of strings) keys and values of arbitrary metadata
          </li>
          <li>
            <code>authz_context.org_id</code>: (string) owning organization ID
          </li>
        </ul>

        <h3>How to write policy Matchers</h3>
        <p>
          A matcher can contain any valid Rego document. At minimum, the result variable <code>match</code> must be defined and set to <code>true</code> or{' '}
          <code>false</code>. Your matcher can be as simple or complex as you&apos;d like.
        </p>
        <p>There are some special Rego functions provided by Raptor to support common use cases:</p>
        <ul>
          <li>
            <code>domainlist_subdomain_match(list_id)</code>: returns true if the incoming query is a subdomain of some domain present in the denoted list
            (referenced by ID).
          </li>
          <li>
            <code>domainlist_exact_match(list_id)</code>: like <code>domainlist_subdomain_match</code> but returns true for exact matches only.
          </li>
          <li>
            <code>is_subdomain(some_domain)</code>: returns true if the incoming query is a subdomain of the input or an exact match. For example{' '}
            <code>is_subdomain(&quot;facebook.com&quot;)</code> returns true for <code>www.facebook.com</code> and <code>facebook.com</code>
          </li>
        </ul>
        <p>
          For more details and help with Rego, see:{' '}
          <a href="https://www.openpolicyagent.org/docs/latest/policy-language/">https://www.openpolicyagent.org/docs/latest/policy-language/</a>
        </p>

        <h3>What are some example policies?</h3>
        <pre>{`
####
## Returns TRUE if incoming query is for facebook.com OR twitter.com or any subdomain thereof.
## (This is a good example of a Logical-OR type of expression.)
##
match {
    is_subdomain("facebook.com")
}
match {
    is_subdomain("twitter.com")
}
`}</pre>
        <pre>{`
####
## Returns TRUE if incoming query is from the given subnet AND is for netflix.com or any subdomain.  
## (This is a good example of a Logical-AND expression.)
##
match {
  net.cidr_contains("192.168.1.0/24", input.request_context.best_ip)
  is_subdomain("netflix.com")
}
`}</pre>
        <pre>{`
####
## Returns TRUE if incoming query is a subdomain match for any member of the identified list. Note that
## this syntax is shorthand for setting match to the result of a single expression.
##
match = domainlist_subdomain_match("aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee")
`}</pre>

        <h3>What do Actions do?</h3>
        <p>An action is associated with a policy. If the policy&apos;s matcher returns TRUE when the action fires. Possible actions are as follows:</p>
        <ul>
          <li>
            <b>Deny</b>: The query will be denied with a REFUSED result code.
          </li>
          <li>
            <b>Allow</b>: The query is allowed. (N.B. like other actions, an Allow action is terminal.)
          </li>
          <li>
            <b>Forward</b>: Forward the query to some other resolver. This is like Allow but you get to specify where the query is resolved.
          </li>
          <li>
            <b>NS1 Answer</b>: Not functional yet.
          </li>
          <li>
            <b>Private Authority</b>: Not functional yet..
          </li>
        </ul>
      </CardContent>
    </Card>
  </>
);
