// © Copyright IBM Corp. 2022, 2024

import config from './config';
import Cookies from 'js-cookie';
import { fetchUtils } from 'ra-core';

export function fetchJsonWithCookie(url, options) {
  options = options || {};
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('X-CSRFToken', Cookies.get(config.csrfCookieName, ''));
  if (options['method'] === 'PATCH') {
    options['method'] = 'PUT';
  }

  let headers = Object.assign(
    Object.assign(
      {
        credentials: 'include',
      },
      options,
    ),
  );

  const token = check_get_api_token();

  if (token) headers.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, headers);
}

const check_get_api_token = () => {
  return sessionStorage.getItem('apiToken');
};

const clear_session_storage = () => {
  sessionStorage.clear();
};

export function authFactory(baseURL) {
  const getheaders = () => {
    const token = check_get_api_token();

    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    };
    return headers;
  };
  const authProvider = {
    // authentication
    login: () => {
      return Promise.resolve(/* ... */);
    },
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        clear_session_storage();
        return Promise.reject();
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      return fetch(`${baseURL}/session/`, {
        credentials: 'include',
        headers: getheaders(),
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            clear_session_storage();
            return Promise.reject({ message: res.statusText ? res.statusText : 'Login failed' });
          } else {
            return Promise.resolve();
          }
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    logout: () => {
      clear_session_storage();
      return fetch(`${baseURL}/logout/`, {
        credentials: 'include',
        headers: getheaders(),
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403 || res.status === 200) {
            clear_session_storage();
            return Promise.resolve();
          }
        })
        .catch(() => {
          return Promise.resolve();
        });
    },
    getIdentity: () => {
      if (check_get_api_token()) {
        return { fullName: 'API Token', email: 'API Token' };
      } else
        return fetch(`${baseURL}/userInfo/`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((res) => {
            if (res.status === 401 || res.status === 403) {
              clear_session_storage();
              return Promise.reject();
            } else return res.json();
          })
          .then((data) => {
            if (data['fullName'].replace(' ', '') === '') {
              data['fullName'] = data['email'] ? data['email'].split('@')[0] : data['username'];
            }
            return data;
          });
    },
    // authorization
    getPermissions: () => {
      return fetch(`${baseURL}/featureFlags/?page_size=100`, {
        credentials: 'include',
        headers: getheaders(),
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            clear_session_storage();
            return Promise.reject({ message: res.statusText ? res.statusText : 'Login failed' });
          } else return res.json();
        })
        .then((data) => {
          return data.results;
        });
    },
  };
  return authProvider;
}
