// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';

const ClientInfo = () => <p>You are logged in more coming soon.</p>;

export const Dashboard = () => (
  <Card>
    <CardHeader title="Raptor Client Portal" />
    <CardContent>
      <ClientInfo />
    </CardContent>
  </Card>
);
