// © Copyright IBM Corp. 2022, 2024

import React from 'react';

import { LoadingIndicator, SimpleShowLayout, Title, useGetIdentity } from 'react-admin';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { CarbonTextField } from '../component/MyInputs';

export const ProfileShow = () => {
  const { isLoading: loading, data: identity, error } = useGetIdentity();

  if (error) {
    return null;
  }
  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <MyBreadcrumbs path="/profile" />
      <Title title="My Profile" />

      <SimpleShowLayout record={identity}>
        <CarbonTextField value={identity.id} label="ID" />
        <CarbonTextField value={identity.username} label="Username" />
        <CarbonTextField value={identity.fullName} label="Full Name" />
        <CarbonTextField value={identity.orgID} label="Org ID" />
        <CarbonTextField value={identity.orgName} label="Org Name" />
        <CarbonTextField value={identity.orgLabel} label="Org Label" />
        <CarbonTextField value={identity.orgDisplayName} label="Org Display Name" />
        <CarbonTextField value={identity.email} label="Email" />
      </SimpleShowLayout>
    </>
  );
};
