// © Copyright IBM Corp. 2022, 2024

import * as React from 'react';

import { List, useListContext } from 'react-admin';

import { Link } from '@carbon/react';

import { Typography } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { MyTable } from '../component/MyTable';

const headers = [
  { key: 'name', header: 'Name' },
  { key: 'updated_at', header: 'Updated At' },
  { key: 'size', header: 'Size' },
  { key: 'link', header: 'Download Link' },
];

export const Table = () => {
  const { data } = useListContext();

  data?.forEach((result) => {
    result.updated_at = <Typography variant="body2"> {new Date(result.updatedAt).toLocaleString()}</Typography>;
    result.link = (
      <Link
        href={result.url}
        target="_blank" // New window
        rel="noopener noreferrer" // For security
      >
        Link
      </Link>
    );
  });

  return (
    <MyTable
      data={data}
      headers={headers}
      showActions={false}
      zebra={false}
      title={'Logs'}
      description={'Decision logs for your organization rolled-up by day. Links expire after 5 mins of page load, reload page to get new links.'}
    />
  );
};
export const ReportList = () => {
  return (
    <>
      <MyBreadcrumbs path={'/logs'} />
      <List sort={{ field: 'updatedAt', order: 'ASC' }} perPage={10} pagination={false} actions={false}>
        <Table />
      </List>
    </>
  );
};
